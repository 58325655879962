<template>
  <el-drawer
    :title="currentId ? '编辑' : '新增'"
    :visible.sync="showDrawer"
    append-to-body
    direction="rtl"
    size="800px"
    @close="onDrawerClose"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      class="edit-form"
      label-position="top"
      v-loading="loading"
      :disabled="submitting"
    >
      <LocaleFormTab @change="onLocaleChange">
        <!-- 中文部分 -->
        <template #cn>
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <el-form-item label="中文名称" prop="title.cn">
                <el-input
                  v-model="form.title.cn"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12">
              <el-form-item
                label="海报标语(slogan)[中文]"
                prop="posterSlogan.cn"
              >
                <el-input
                  v-model="form.posterSlogan.cn"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="24">
              <el-form-item label="中文描述" prop="description.cn">
                <el-input
                  v-model="form.description.cn"
                  type="textarea"
                  :rows="6"
                  placeholder="请输入内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <!-- 英文部分 -->
        <template #en>
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <el-form-item label="英文名称" prop="title.cn">
                <el-input
                  v-model="form.title.en"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12">
              <el-form-item
                label="海报标语(slogan)[英文]"
                prop="posterSlogan.en"
              >
                <el-input
                  v-model="form.posterSlogan.en"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="24">
              <el-form-item label="英文描述" prop="description.en">
                <el-input
                  v-model="form.description.en"
                  type="textarea"
                  :rows="6"
                  placeholder="请输入内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </LocaleFormTab>

      <el-divider content-position="left">以下为通用部分</el-divider>

      <el-row :gutter="10">
        <el-col :sm="24" :md="8">
          <el-form-item label="发布日期" prop="postAt">
            <el-date-picker
              class="form-select"
              v-model="form.postAt"
              type="date"
              value-format="timestamp"
              clearable
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item label="次序(数值越大越靠前)" prop="seq">
            <el-input-number
              v-model="form.seq"
              label="请输入"
              :min="1"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="24">
          <el-form-item label="标签(多选)" prop="tagIds">
            <AreicleTagCascader
              :cascaderProps="{
                multiple: true,
              }"
              v-model="form.tagIds"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :sm="24" :md="12">
          <el-form-item label="封面图片" prop="descriptionImageUrl">
            <p class="form-tips">
              提示：该图会用在列表的封面图，图片宽除于高的比值保持在1.5，建议900*600同等比例的图，大小保持在1MB左右，越高清越好
            </p>
            <SingleImgUpload
              class="cover-img"
              :size="2"
              v-model="form.descriptionImageUrl"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12">
          <el-form-item label="海报图" prop="posterImageUrl">
            <p class="form-tips">
              提示：用作海报宣传图，建议宽高【1125x2436】等同比例（宽高比0.46）尺寸的图片,建议大小保持在1.5MB以内
            </p>
            <SingleImgUpload
              :size="2"
              class="poster-img"
              v-model="form.posterImageUrl"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :sm="24" :md="24">
          <el-form-item label="文章类型">
            <el-radio-group v-model="contentType">
              <el-radio-button label="link">第三方链接</el-radio-button>
              <el-radio-button label="content">编写内容</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="24" v-if="contentType == 'link'">
          <el-form-item label="文章链接" prop="referenceUrl">
            <el-input
              v-model="form.referenceUrl"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="24" v-else>
          <el-form-item
            label="文章内容(中文)"
            prop="content.cn"
            v-if="currentLocalName == 'cn'"
          >
            <el-input
              v-model="form.content.cn"
              clearable
              type="textarea"
              :rows="10"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <!-- 英文内容 -->
          <el-form-item
            label="文章内容(英文)"
            prop="content.en"
            v-if="currentLocalName == 'en'"
          >
            <el-input
              v-model="form.content.en"
              clearable
              type="textarea"
              :rows="10"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item style="text-align: right">
        <!-- <el-button @click="onCancel('form')">关 闭</el-button> -->
        <el-button @click="onResetForm('form')">重 置</el-button>
        <el-button
          v-if="currentId"
          type="warning"
          @click="submitEidtForm('form')"
          :loading="submitting"
          >{{ submitting ? "保存中..." : "保存" }}</el-button
        >
        <el-button
          v-else
          type="primary"
          @click="submitForm('form')"
          :loading="submitting"
          >{{ submitting ? "保存中..." : "保存" }}</el-button
        >
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
import { PostArticle, PutArticle, GetArticleById } from "../api";
import { ShowApiError } from "@/request/error";
import SingleImgUpload from "@/components/SingleImgUpload";
import API_CONFIG from "@/request/api.config";
import { getToken } from "@/utils/auth";
import { buildI18nKeyValue } from "@/utils/common";
import AreicleTagCascader from "@/views/articleTags/components/AreicleTagCascader";
import LocaleFormTab from "@/components/LocaleFormTab";
export default {
  props: {
    sid: {
      type: [String, Number],
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SingleImgUpload,
    LocaleFormTab,
    AreicleTagCascader,
  },
  data() {
    return {
      currentLocalName: "cn",
      currentId: null,
      loading: false,
      submitting: false,
      showDrawer: false,
      contentType: "link",
      form: {},
      rules: {
        "title.cn": [{ required: true, message: "请输入", trigger: "blur" }],
        "title.en": [{ required: true, message: "请输入", trigger: "blur" }],
        referenceUrl: [
          { required: true, message: "请输入文章链接", trigger: "blur" },
        ],
        postAt: [
          { required: true, message: "请选择发布时间", trigger: "change" },
        ],
        descriptionImageUrl: [
          { required: true, message: "请上传封面图", trigger: "blur" },
        ],
        seq: [
          {
            required: true,
            message: "请输入次序",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    headers() {
      return {
        Authorization: getToken(),
      };
    },
    onUploadUrl() {
      return API_CONFIG.BASE_V1_API + "/oss/upload/cdn";
    },
    localeColumns() {
      return this.$store.getters.localeColumns;
    },
    predefineColors() {
      return this.$store.getters.predefineColors;
    },
  },
  watch: {
    sid: {
      handler(value) {
        if (value !== null) {
          this.currentId = value;
        } else {
          this.currentId = null;
          this.initForm();
        }
      },
      immediate: true,
    },
    show: {
      handler(value) {
        this.showDrawer = value;
        if (this.currentId) {
          this.getFormData();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initForm();
  },
  methods: {
    onLocaleChange(e) {
      let name = e.name;
      this.currentLocalName = name;
    },
    buildFormParams() {
      return this.form;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostArticle([this.buildFormParams()])
            .then(() => {
              this.$message.success("保存成功!");
              this.initForm();
              this.submitting = false;
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("保存错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    submitEidtForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutArticle([
            {
              id: this.currentId,
              ...this.buildFormParams(),
            },
          ])
            .then(() => {
              this.$message.success("保存成功!");
              this.initForm();
              this.submitting = false;
              this.getFormData();
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("保存错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData() {
      this.loading = true;
      GetArticleById(this.currentId)
        .then((res) => {
          this.form = this.buildFormItem();
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.form[key] = res.data[key];
            }
          }
          this.form.title = buildI18nKeyValue(res.data.title);
          this.form.content = buildI18nKeyValue(res.data.content);
          this.form.description = buildI18nKeyValue(res.data.description);
          this.form.posterSlogan = buildI18nKeyValue(res.data.posterSlogan);
          this.form.tagIds = res.data.tags.map((item) => item.id);
          this.contentType = res.data.referenceUrl !== "" ? "link" : "content";
          this.loading = false;
        })

        .catch((err) => {
          this.loading = false;
          ShowApiError( "获取表单数据失败",err);
        });
    },
    buildFormItem() {
      return {
        title: { ...this.localeColumns },
        postAt: new Date().getTime(),
        description: { ...this.localeColumns },
        descriptionImageUrl: "",
        content: { ...this.localeColumns },
        referenceUrl: "",
        posterImageUrl: "",
        posterSlogan: { ...this.localeColumns },
        tagIds: [],
        seq: 1,
      };
    },
    initForm() {
      this.form = {
        ...this.buildFormItem(),
      };
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
      });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    onResetForm() {
      this.$confirm("确定要重置表单吗?", "操作确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.currentId) {
            this.getFormData();
          } else {
            this.initForm();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onDrawerClose() {
      this.showDrawer = false;
      this.$emit("close", this.showDrawer);
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.medias-list-wrap {
  display: inline-block;
  max-width: 100%;
  ::v-deep {
    .transition-el {
      max-height: none;
    }
  }
  .medias-item {
    display: block;
    width: 100px;
    height: 100px;
    margin: 5px;
  }
  .video-medias-item {
    box-sizing: border-box;
    padding: 5px 0;
    margin: 5px;
    width: 280px;
    height: 100px;
    position: relative;
    padding-right: 20px;
    background-color: #edfae8;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .remove-video-icon {
      position: absolute;
      bottom: 5px;
      right: 2px;
      font-size: 16px;
      cursor: pointer;
      color: #c51515;
    }
    ::v-deep {
      .basic-upload-wrap {
        width: 150px;
        height: 90px;
      }
      .img-upload-wrap {
        width: 90px;
        height: 90px;
      }
    }
  }
}
.add-madias-item {
  display: block;
  width: 100%;
  height: 40px;
  font-size: 30px;
  margin: 0 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}
.poster-img {
  width: 150px;
  height: 326px;
}
.edit-form {
  padding: 0 20px;
}
.form-tips {
  font-size: 12px;
  margin: 0;
  color: #989898;
}
.cover-img {
  width: 300px;
  height: 200px;
}
.file-list-wrap {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  min-height: 300px;
  border: 1px dashed #f1f1f1;
  background-color: #fffdfc;
  ::v-deep {
    .transition-el {
      box-sizing: border-box;
      width: 100%;
      padding-right: 10px;
    }
    .draggable-item {
      width: 100%;
    }
  }
  .file-item {
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    padding: 10px 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    gap: 10px;
    border: 1px solid #f1f1f1;
    color: #989898;
    overflow: hidden;

    .darg-btn {
      width: 40px;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      font-size: 20px;
      color: inherit;
      cursor: move;
    }
    .delete-btn {
      width: 40px;
      position: absolute;
      top: 50%;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      font-size: 20px;
      color: inherit;
      cursor: pointer;
      &:hover {
        color: #ff3d3d;
      }
    }
    &:hover {
      // color: #fff;
      // background-color: hsl(15, 88%, 55%);
      border: 1px solid hsl(15, 88%, 55%);
      .darg-btn {
        color: #fff;
        background-color: hsl(15, 88%, 55%);
      }
    }
    .image-wrapper {
      box-sizing: border-box;
      padding: 4px;
      flex: 130px 0 0;
      height: 100%;
      border: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 56px;
      overflow: hidden;
      color: #ff3d3d;
      align-self: start;
      background-color: #f7f5f5;
      a {
        color: inherit;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      ::v-deep {
        .el-image {
          background-color: transparent;
        }
        .el-image__inner {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .file-content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      gap: 4px;
    }
  }
}
</style>
